import { navigate } from 'gatsby'

export const isBrowser = () => typeof window !== 'undefined'

export const getLanguage = () =>
  isBrowser() && window.location.href.includes('/en/')
    ? 'en_US'
    : 'nl_NL'

// export const setLanguage = language =>
//     language === "nl_NL" ? navigate('/') : navigate('/en/home/')

export const setLanguage = node =>
  navigate(node.path)
