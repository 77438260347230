/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// Services
import { getLanguage } from 'services/language'
// Components
import CustomLink from 'components/CustomLink'
// CSS
import './Footer.scss'
// Images
import partnerLogo from 'img/ssi_logo.svg'
import logo from 'img/logo.svg'
import facebook from 'img/facebook.svg'
import instagram from 'img/instagram.svg'
import linkedin from 'img/linkedin.svg'
import youtube from 'img/youtube.svg'

// Third Party
import { ButtonAlt } from '../Elements/Elements'

const Footer = ({ extraFooterPiece }) => {
  const language = getLanguage()

  const {
    footerNL, footerEN,
  } = useStaticQuery(graphql`
		query {
			footerNL: wordpressWpComponenten(slug: {eq: "footer"}, wpml_current_locale: {eq: "nl_NL"}) {
				...footerContent
			}

			footerEN: wordpressWpComponenten(slug: {eq: "footer"}, wpml_current_locale: {eq: "en_US"}) {
				...footerContent
			}
		}

		fragment footerContent on wordpress__wp_componenten {
			id
			acf {
				locations {
					location_address
					location_zipcode_city
				}
				social_information {
					facebook_link
					instagram_link
					linkedin_link
					phonenumber
					mail
				}
				services {
					links {
						path
						slug
						title
					}
				}
				menu {
					links {
						path
						slug
						title
					}
				}
			}
		}
  `)

  const {
    acf: {
      services: {
        links: servicesLinks,
      },
      menu: {
        links: menuLinks,
      },
      locations,
      social_information: socialInformation,
    },
  } = language === 'nl_NL' ? footerNL : footerEN

  return (
    <div className="footer text-lg-left text-center color-background-secondary">
      <div className="pt-5 pb-3 container position-relative">
        {extraFooterPiece && (
          <div className="extra-column color-background-light d-flex position-absolute justify-content-center">
            <div className="px-5 w-100 row">
              <div
                className="col-12 col-lg-8 pt-2 d-flex justify-content-start align-content-center flex-wrap text-left"
              >
                {language === 'nl_NL' ? (
                  <>
                    <h2 className="text-center text-lg-left font-weight-xl font-size-l">
                      Wat kunnen wij voor u
                      betekenen?
                    </h2>
                    <p className="m-0 text-center text-lg-left">
                      Neem contact met ons op! Wij helpen u graag om
                      uw merk effectief tot leven te laten komen.
                    </p>
                  </>
                ) : (
                  <>
                    <h2 className="text-center text-lg-left font-weight-xl font-size-l">
                      How could we
                      service you?
                    </h2>
                    <p className="m-0 text-center text-lg-left">
                      Please contact us! We gladly would like to
                      support you in bringing your brand to live.
                    </p>
                  </>
                )}
              </div>
              <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
                <ButtonAlt className="px-3 font-size-ms" to="/contact">{language === 'nl_NL' ? `Contact opnemen` : `Contact us`}</ButtonAlt>
              </div>
            </div>
          </div>
        )}
        <div className="py-5 row">
          <div className="col-12 mb-4 mb-lg-0 col-lg-3">
            <div className="pb-2 pb-lg-4">
              <h2
                className="font-size-m font-weight-xl color-text-main"
              >
                {language === 'nl_NL' ? 'Vestigingen' : 'Locations'}

              </h2>
            </div>
            <div>
              {locations.map((location, key) => (
                <div className="pb-4" key={key}>
                  <p className="mb-1 color-text-main">{location.location_address}</p>
                  <p className="mb-1 color-text-main">{location.location_zipcode_city}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 mb-4 mb-lg-0 col-lg-3">
            <div className="pb-2 pb-lg-4">
              <h2
                className="font-size-m font-weight-xl color-text-main"
              >
                {language === 'nl_NL' ? 'Diensten' : 'Services'}

              </h2>
            </div>
            <div className="d-flex flex-wrap">
              {servicesLinks.map((link, key) => (
                <CustomLink
                  to={link.path}
                  key={key}
                  className={`mb-1 w-100 link-item text-capitalize ${link.title === `Voor retailers` || link.title === `For retailers` ? `pt-4` : ``}`}
                >
                  {link.title}
                </CustomLink>
              ))}
            </div>
          </div>
          <div className="col-12 mb-4 mb-lg-0 col-lg-3">
            <div className="pb-2 pb-lg-4">
              <h2 className="font-size-m font-weight-xl color-text-main">{language === 'nl_NL' ? 'Menu' : 'Menu'}</h2>
            </div>
            <div className="d-flex flex-wrap">
              {menuLinks.map((link, key) => (
                <CustomLink
                  to={link.path}
                  key={key}
                  className="mb-1 w-100 link-item text-capitalize"
                >
                  {link.title}
                </CustomLink>
              ))}
            </div>
          </div>
          <div className="col-12 mb-4 mb-lg-0 col-lg-3">
            <div className="pb-4">
              <img alt="brandwise" src={logo} />
            </div>
            <div className="pb-4 d-flex justify-content-center justify-content-lg-start">
              <a href={socialInformation.instagram_link}>
                <img className="social-icon pl-3 pl-lg-0 pr-3" alt="instagram" src={instagram} />
              </a>
              <a href={socialInformation.facebook_link}>
                <img className="social-icon pl-3 pl-lg-0 pr-3" alt="facebook" src={facebook} />
              </a>
              <a href={socialInformation.linkedin_link}>
                <img className="social-icon pl-3 pl-lg-0 pr-3" alt="linkedin" src={linkedin} />
              </a>
              <a href={socialInformation.youtube_link}>
                <img className="social-icon pl-3 pl-lg-0 pr-3" alt="linkedin" src={youtube} />
              </a>
            </div>
            <div className="d-flex flex-wrap">
              <a
                href={`tel:${socialInformation.phonenumber}`}
                className="mb-1 w-100 font-size-m color-text-contrast font-weight-xl"
              >
                {socialInformation.phonenumber}

              </a>
              <a
                href={`mailto:${socialInformation.mail}`}
                className="mb-1 w-100 font-size-m color-text-contrast font-weight-xl"
              >
                {socialInformation.mail}

              </a>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <p className="m-0 mr-3 oblique">The Power of Human Connection</p>
          <CustomLink className="font-size-sm color-text-grey" to="/privacy">Privacyverklaring</CustomLink>
          <p className="mb-0 mx-1 font-size-sm color-text-grey">|</p>
          <CustomLink className="font-size-sm color-text-grey" to="/disclaimer">Disclaimer</CustomLink>
        </div>
      </div>
      <div className="sub-footer color-background-main">
        <a
          href="http://ssinetwork.nl"
          target="_blank"
          rel="noopener noreferrer"
          className="p-4 d-flex flex-wrap justify-content-center align-items-center container"
        >
          <img className="pb-lg-0 pb-3" alt="SSI" src={partnerLogo} />
          <p className="m-0 pl-lg-4 color-text-light font-size-sm font-weight-xl">
            Proud member of European Network
            <br />
            of independent Field Marketing & Sales agencies
          </p>
        </a>
      </div>
    </div>
  )
}

export default Footer
