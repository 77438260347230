import React, { useState, Suspense, lazy } from 'react'

// Third Party
import styled from 'styled-components'
import Modal from 'react-modal'

// Images
import playButton from 'img/play-button.svg'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'styles/all.scss'
import { isBrowser } from '../services/auth'
import { getLanguage } from '../services/language'

const CookieConsent = lazy(() => import('react-cookie-consent'));

const SideCTA = styled.button`
  position: fixed;
  right: -95px;
  top: 300px;
  z-index: 50;
  cursor: pointer;
  transform: rotate(270deg);
  
  div {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    p {
      line-height: 20px;
    }
  }

  @media (max-width: 991px) {
    top: 140px;
    right: -70px;

    p {
      font-size: 12px;
    }

    img {
      height: 20px;
    }
  }
`

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 60;
`

const InnerModal = styled.div`
  height: 325px;
  width: 575px;

  iframe {
    border: none;
  }

  @media (max-width: 767px) {
    height: 225px;
    width: 425px;
  }

  @media (max-width: 575px) {
    height: 225px;
    width: 350px;
  }

  @media (max-width: 350px) {
    height: 225px;
    width: 300px;
  }
`

const CustomStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '60'
  }
}

const Layout = ({ children, extraFooterPiece }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]')
  }

  const [modalIsOpen, setIsOpen] = useState(false)

  const afterOpenModal = () => {
    // after modal opened
  }

  return (
    <>
      <Header />
      <SideCTA onClick={() => setIsOpen(true)}>
        <div className="color-background-contrast d-flex justify-content-between py-2 px-3 align-items-center">
          <img className="pr-4" src={playButton} alt="" />
          <p className="m-0 font-size-m text-left color-text-light">
            The Power
            <br />
            of Human Connection
          </p>
        </div>
      </SideCTA>
      <StyledModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        style={CustomStyles}
        contentLabel="example modal"
      >
        <InnerModal className="color-background-light d-flex justify-content-center align-items-center">
          <iframe allow="autoplay; fullscreen" allowfullscreen title="Video" className="w-100 h-100 p-1" src={getLanguage() === 'nl_NL' ? 'https://player.vimeo.com/video/485852031' : "https://player.vimeo.com/video/485890462"} />
        </InnerModal>
      </StyledModal>
      {children}
      <Footer extraFooterPiece={extraFooterPiece} />
      {isBrowser() && (
        <Suspense fallback="">
          <CookieConsent
            location="bottom"
            style={{ backgroundColor: '#8eae39', color: '#FFFFFF' }}
            buttonClasses="color-background-main text-white"
            buttonStyle={{ backgroundColor: '#001843' }}
            buttonText="Akkoord"
            buttonWrapperClasses="d-flex flex-column justify-content-center h-100"
          >
            <div className="container px-lg-5">
              <div className="px-lg-5">
                <div>
                  We gebruiken cookies om ervoor te zorgen dat onze website zo
                  soepel mogelijk draait. Als u doorgaat met het gebruiken van de
                  website, gaan we er vanuit dat u ermee instemt.
                </div>
              </div>
            </div>
          </CookieConsent>
        </Suspense>
      )}
    </>
  )
}

export default Layout
