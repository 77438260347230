import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { 'aria-current': `page` } : null

  const CustomLink = ({ state, external, newPage, to, className, children }) => (
    <>
      {external ? (
        <a rel="noopener noreferrer" target={`${newPage ? `_blank` : ``}`} href={to} className={className}>
          {children}
        </a>
      ) : (
        <Link state={state} getProps={isPartiallyActive} to={to} className={className}>
          {children}
        </Link>
      )}
    </>
  )

export default CustomLink
