import React from 'react'

import styled from 'styled-components'

const StyledContent = styled.div`
  h3 {
    font-weight: 900;
    font-size: 30px;
    color: #2b2652;
  }
`

const Content = ({ content, className }) => (
  <StyledContent className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

export default Content
