/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
// Images
import imgLogo from 'img/logo.svg'
import houseIcon from 'img/house.svg'
import chevronDown from 'img/chevron-down.svg'
import menuIcon from 'img/menu-icon.svg'
import menuCloseIcon from 'img/menu-close-icon.svg'
import facebookIcon from 'img/facebook.svg'
import instagramIcon from 'img/instagram.svg'
import linkedinIcon from 'img/linkedin.svg'
import youtubeIcon from 'img/youtube.svg'
import phoneIcon from 'img/phone.svg'
import ukFlag from 'img/uk-flag.svg'
import nlFlag from 'img/netherlands-flag.svg'

// Components
import CustomLink from 'components/CustomLink'
import { getLanguage } from 'services/language'
import Content from 'components/Content'
import { ButtonAlt } from 'components/Elements'

// CSS
import './Header.scss'

// Third Party
import { size, find, each, replace, concat } from 'lodash'
import { setLanguage } from '../../services/language'

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true)
  const language = getLanguage()

  function setCollapsedAndOverflow(boolean) {
    window.scrollTo(0, 0)
    setCollapsed(boolean)
    document.querySelector('html').classList.toggle('menu-is-open')
  }

  useEffect(() => {
    if (isCollapsed)
      document.querySelector('html').classList.remove('menu-is-open')
  }, [isCollapsed])


  const {
    headerNL,
    headerEN,
    headerContentNL,
    headerContentEN,
    pages,
    posts,
    campagnes,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
		query HeaderQuery {
			headerNL: wordpressMenusMenusItems(term_id: {eq: 9}) {
				name
				items {
					title
					url
					child_items {
						title
						url
					}
				}
			}

			headerEN: wordpressMenusMenusItems(term_id: {eq: 12}) {
				name
				items {
					title
					url
					child_items {
						title
						url
					}
				}
			}

			headerContentNL: wordpressWpComponenten(wordpress_id: {eq: 73}, wpml_current_locale: {eq: "nl_NL"}) {
				...headerContent
			}

			headerContentEN: wordpressWpComponenten(wordpress_id: {eq: 75}, wpml_current_locale: {eq: "en_US"}) {
				...headerContent
			}

			site {
				pathPrefix
			}

			pages: allWordpressPage {
				edges {
					node {
						path
						locale: wpml_current_locale
						translations: wpml_translations {
							locale
							path: href
						}
					}
				}
			}

			posts: allWordpressPost {
				edges {
					node {
						path
						locale: wpml_current_locale
						translations: wpml_translations {
							locale
							path: href
						}
					}
				}
			}

			campagnes: allWordpressWpCampagnes {
				edges {
					node {
						path
						locale: wpml_current_locale
						translations: wpml_translations {
							locale
							path: href
						}
					}
				}
			}
		}

		fragment headerContent on wordpress__wp_componenten {
			id
			acf {
				for_retailers
        work_at_link {
          title 
          url
        }
			}
		}
  `)

  const headerContent = language === 'nl_NL' ? headerNL : headerEN

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = replace(pathname, pathPrefix, '')
  }

  const allPages = concat(posts.edges, pages.edges)
  let toPage = null

  each(allPages, ({ node }) => {
    if (node.path === pathname) {
      toPage = node.translations[0]
    }

    if (size(find(node.translations, ({ path }) => path === pathname)) > 0) {
      toPage = node
    }
  })

  if (!toPage) {
    toPage = language === 'nl_NL' ? { path: '/en/home/' } : { path: '/' }
  }

  const {
    acf: {
      for_retailers: forRetailers,
      work_at_link: workAtLink
    },
  } = language === 'nl_NL' ? headerContentNL : headerContentEN
  return (
    <nav className="header position-relative">
      <div className="header-top">
        <div className="container h-100 d-flex align-items-center justify-content-between">
          <div className="header-top-icons d-flex h-100 align-items-center">
            <a rel="noopener noreferrer" target="_blank" href="https://facebook.com/BrandwiseGroupBV">
              <img alt="Facebook" src={facebookIcon} />
            </a>

            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/brandwisegroup/">
              <img alt="Instagram" src={instagramIcon} />
            </a>

            <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/brandwise-group-b-v-/">
              <img alt="LinkedIn" src={linkedinIcon} />
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCn4sOR3ke88QF5XXPL9J6Ww"
            >
              <img alt="LinkedIn" src={youtubeIcon} />
            </a>
            <div className="color-background-contrast d-flex h-100 justify-content-center align-items-center">
              <a href={workAtLink.url} target="_blank" className="color-background-contrast w-100 h-100 mr-0 d-flex justify-content-center align-items-center">
                <p className="px-3 m-0 color-text-light font-weight-l">{workAtLink.title}</p>
              </a>
            </div>
          </div>

          <TopBarItems language={language} toPage={toPage} forRetailers={forRetailers}  />
        </div>
      </div>
      <div className="navbar-container container">
        <div className="navbar-brand">
          <Link to={language === 'nl_NL' ? '/' : '/en/'} className="navbar-item">
            <img src={imgLogo} alt="" />
          </Link>
        </div>

        <button
          type="button"
          onClick={() => setCollapsedAndOverflow(!isCollapsed)}
          className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''} text-center d-block d-lg-none`}
        >
          {isCollapsed ? (
            <img src={menuIcon} alt="" />
          ) : (
            <img src={menuCloseIcon} alt="" />
          )}
        </button>
           
        <HeaderMenuMobile
          toPage={toPage}
          forRetailers={forRetailers}
          workAtLink={workAtLink}
          language={language}
          items={headerContent.items}
          isCollapsed={isCollapsed}
          setCollapse={setCollapsed}
        />
        <HeaderMenuDesktop items={headerContent.items} />
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ language, toPage, items, isCollapsed, workAtLink, forRetailers }) => (
  <CSSTransition in={!isCollapsed} timeout={300} unmountOnExit classNames="header-menu">
    <div className="navbar-menu mobile-navbar">
      <ul className="navbar-items">
        <HeaderMenuItems items={items} />
      </ul>
      <TopBarItems workAtLink={workAtLink} languag={language} toPage={toPage} forRetailers={forRetailers} />
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ items }) => (
  <ul className='navbar-items mt-3 d-none d-lg-flex'>
    <HeaderMenuItems items={items} />
  </ul>
)

const HeaderMenuItems = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <li key={index}>
          {item.title === 'Home' ? (
            <CustomLink className="home" to={item.url}>
              <img src={houseIcon} alt="home" />
            </CustomLink>
          ) : (
            <span className={`header-a${item.child_items !== null ? ' has-children' : ''}`}>
              <CustomLink to={item.url}>
                {item.title}
              </CustomLink>

              {item.child_items !== null && (
                <>
                  <img className="dropdown-icon" src={chevronDown} alt="" />
                  <ul className="sub-menu">
                    {item.child_items.map((childItem, key) => (
                      <li key={key} className="sub-menu-item">
                        <CustomLink to={childItem.url}>
                          <Content content={childItem.title} />
                        </CustomLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </span>
          )}
        </li>
      ))}
    </>
  )
}

const TopBarItems = ({ language, forRetailers, toPage }) => (
  
  <div className="header-top-links">
    <div className="color-background-main d-flex h-100 justify-content-center align-items-center">
      <CustomLink
        className="werkenbij-link color-background-main w-100 h-100 d-flex justify-content-center align-items-center"
        to={language === 'nl_NL' ? `/diensten/voor-retailers/` : `/en/services/for-retailers/`}
      >
        <p className="px-4 m-0 color-text-light font-weight-xl">{forRetailers}</p>
      </CustomLink>
    </div>

    <a
      href="tel:+31887701000"
      className="phone d-flex align-items-center px-3 text-white color-background-contrast h-100"
    >
      <img src={phoneIcon} alt="" className="mr-2" />
      +31 88 770 1000
    </a>

    <div className="color-background-contrast d-flex d-lg-none h-100 justify-content-center align-items-center">
      <a href="https://www.werkenbijbrandwise.nl" target="_blank" className="color-background-contrast w-100 h-100 mr-0 py-1 d-flex justify-content-center align-items-center">
        <p className="px-3 m-0 color-text-light font-weight-l">{language === 'nl_NL' ? 'Brandwiser worden?' : 'Be a Brandwiser?'}</p>
      </a>
    </div>

    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => setLanguage(toPage)}
      onClick={() => setLanguage(toPage)}
      className={`flag ml-3 mr-0 justify-content-center align-items-center${getLanguage() === 'nl_NL' ? ' active' : ''}`}
    >
      <img src={nlFlag} alt="Dutch flag" />
    </div>

    <div
      role="button"
      tabIndex={-1}
      onKeyDown={() => setLanguage(toPage)}
      onClick={() => setLanguage(toPage)}
      className={`flag mx-2 d-flex justify-content-center align-items-center${getLanguage() === 'en_US' ? ' active' : ''}`}
    >
      <img src={ukFlag} alt="United Kingdom flag" />
    </div>
  </div>
)

export default Header
