/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import CustomLink from 'components/CustomLink'
import Img from 'gatsby-image'

// CSS
import './Elements.scss'

// Third Party
import styled from 'styled-components'

export const ButtonDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-alt${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonLineDefault = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-default${className ? ` ${className}` : ``}`

  return (
    <ButtonShell to={to} isAnchor={isAnchor} className={classes}>
      {children}
    </ButtonShell>
  )
}

export const ButtonLineAlt = ({ isAnchor, to, children, className }) => {
  const classes = `button button-line-alt${className ? ` ${className}` : ``}`

  return <ButtonShell to={to} isAnchor={isAnchor} className={classes}>{children}</ButtonShell>
}

const ButtonShell = ({ isAnchor, to, children, className }) => (
  !isAnchor ? (
    <CustomLink to={to} className={className}>{children}</CustomLink>
  ) : (
    <a href={to} className={className}>{children}</a>
  )
)

export const TitleDefault = ({ children, className }) => (
  <h2 className={`title title-default${className ? ` ${className}` : ``}`}>{children}</h2>
)

export const TitleAlt = ({ children, className }) => (
  <h2 className={`title title-alt${className ? ` ${className}` : ``}`}>{children}</h2>
)

export const TitleSubDefault = ({ children, className }) => (
  <div className={`title title-sub-default${className ? ` ${className}` : ``}`}>{children}</div>
)

export const BoxDefault = ({ children, className }) => (
  <div className={`box${className ? ` ${className}` : ``}`}>{children}</div>
)

export const ContentDefault = ({ children, className, html }) => (
  <div className={`content${className ? ` ${className}` : ``}`} dangerouslySetInnerHTML={{ __html: html }}>
    {children}
  </div>
)

export const BannerDefault = ({ children, className }) => (
  <div className={`banner${className ? ` ${className}` : ``}`}>
    <div className="container">
      {children}
    </div>
  </div>
)

export const BackgroundColor = ({ children, className, center }) => (
  <div className={`${className ? `${className}` : ``}`}>
    <div className="py-3 container">
      <div className={`row${center ? ' justify-content-center' : ''}`}>
        {children}
      </div>
    </div>
  </div>
)

const StyledImg = styled(Img)`
  height: 100%;
  width: 100%;
`

export const Video = ({ replacement, url, title, className }) => (
  <div className={`video position-relative ${className ? `${className}` : ``}`}>
    <div className="video-shadow color-background-main" />
    <div className="video-frame">
      {/* <StyledImg fluid={replacement} alt="" /> */}
      <iframe allow="autoplay; fullscreen" allowfullscreen title={title} src={url} />
    </div>
  </div>
)